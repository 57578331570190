<template>
    <div>
        <LiefengContent>
            <template v-slot:title>
                {{ "民情档案初始化拆分" }}
                <span style="margin-left: 20px; color: #00bfbf">{{ $route.query.name }}</span>
            </template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true">
                    <!-- <FormItem style="user-select:none">
                        <Checkbox v-model="search.type" true-value="1" false-value="" style="margin-right:10px" @on-change="changeCheck">失败</Checkbox>
                    </FormItem> -->
                    <Button :type="schedule > 0 ? 'success' : ''" :loading="table.splitLoading" style="margin-right: 0px" @click="splitPhone">① 分类证件拆分手机号</Button>
                    <Icon type="md-play" :color="schedule > 0 ? '#29BE6C' : '#AAAAAA'" />

                    <Button :type="schedule > 1 ? 'success' : ''" :loading="table.btnCheck" style="margin-right: 10px" @click="reCheck"  :disabled="schedule > 0 ? false : true">② 校验</Button>
                    <!-- <Icon type="md-play" :color="schedule > 1 ? '#29BE6C' : '#AAAAAA'" />

                    <Button :type="schedule > 2 ? 'success' : ''"  :loading="table.btnLoading" style="margin-right: 10px" @click="reSetData"  :disabled="schedule > 1 ? false : true">③ 初始化到民情档案</Button> -->

                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="table.talbeColumns"
                    :tableData="table.tableData"
                    :loading="table.loading"
                    :fixTable="true"
                    :curPage="table.page"
                    :total="table.total"
                    :pagesizeOpts="[100, 200, 300]"
                    :page-size="table.pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            search: {
                type: "",
            },
            table: {
                talbeColumns: [
                    {
                        key: "msgType",
                        align: "center",
                        title: "校验结果",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: params.row.msgType == 2 ? "red" : "black",
                                    },
                                },
                                params.row.msgType == "1" ? "成功" : "失败"
                            )
                        },
                    },
                    {
                        key: "msg",
                        align: "center",
                        title: "校验信息",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: params.row.msgType == 2 ? "red" : "black",
                                    },
                                },
                                params.row.msg
                            )
                        },
                    },
                    {
                        key: "houseName",
                        align: "center",
                        title: "门牌号",
                        minWidth: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: params.row.msgType == 2 ? "red" : "black",
                                    },
                                },
                                params.row.houseName
                            )
                        },
                    },
                    {
                        key: "address",
                        align: "center",
                        title: "清理后门牌号",
                        minWidth: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: params.row.msgType == 2 ? "red" : "black",
                                    },
                                },
                                params.row.address
                            )
                        },
                    },
                    {
                        key: "memberName",
                        align: "center",
                        title: "姓名",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: params.row.msgType == 2 ? "red" : "black",
                                    },
                                },
                                params.row.memberName
                            )
                        },
                    },
                    {
                        key: "idNumType",
                        align: "center",
                        title: "证件类型",
                        minWidth: 150,
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Select",
                                    {
                                        props: {
                                            value: params.row.idNumType,
                                        },
                                        on: {
                                            "on-change": e => {
                                                let value = ""
                                                if (e) value = e
                                                else value = params.row.idNumType
                                                this.table.tableData.map(item => {
                                                    if (item.id == params.row.id) {
                                                        item.idNumType = value
                                                    }
                                                })
                                            },
                                        },
                                    },
                                    this.table.userTypeList.map(item => {
                                        return h(
                                            "Option",
                                            {
                                                props: {
                                                    value: item.value,
                                                },
                                            },
                                            item.lable
                                        )
                                    })
                                ),
                            ])
                        },
                    },
                    {
                        key: "idNum",
                        align: "center",
                        title: "证件号码",
                        minWidth: 200,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.idNum,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.deboounceFun(e.target.value,'idNum',params.row.id)
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "phone",
                        align: "center",
                        title: "手机号（校验账号）",
                        minWidth: 150,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.phone,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.deboounceFun(e.target.value,'phone',params.row.id)
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "phoneTwo",
                        align: "center",
                        title: "手机号2",
                        minWidth: 150,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.phoneTwo,
                                    },
                                    on: {
                                        "on-change": e => {
                                             this.deboounceFun(e.target.value,'phoneTwo',params.row.id)
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "fixedPhone",
                        align: "center",
                        title: "固话1",
                        minWidth: 150,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.fixedPhone,
                                    },
                                    on: {
                                        "on-change": e => {
                                           this.deboounceFun(e.target.value,'fixedPhone',params.row.id)
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "fixedPhoneTwo",
                        align: "center",
                        title: "固话2",
                        minWidth: 150,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.fixedPhoneTwo,
                                    },
                                    on: {
                                        "on-change": e => {
                                             this.deboounceFun(e.target.value,'fixedPhoneTwo',params.row.id)
                                        },
                                    },
                                }),
                            ])
                        },
                    },

                    {
                        title: "操作",
                        align: "center",
                        minWidth: 50,
                        fixed: "right",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "error",
                                            size: "small",
                                        },
                                        nativeOn: {
                                            click: () => {
                                                this.table.tableData.some((item, index) => {
                                                    if (item.id == params.row.id) {
                                                        this.table.tableData.splice(index, 1)
                                                    }
                                                })
                                                this.table.total = this.table.tableData.length
                                            },
                                        },
                                    },
                                    "删除"
                                ),
                            ])
                        },
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 100,
                currentPage: 1,
                btnLoading: false,
                btnCheck: false,
                splitLoading:false,
                userTypeList: [
                    { value: "1", lable: "内地身份证" },
                    { value: "2", lable: "港澳身份证" },
                    { value: "3", lable: "护照" },
                    { value: "4", lable: "其它" },
                ],
            },
            timer:null,
            schedule:0,
        }
    },
    async created() {
        await this.getList()
    },
    methods: {
        logBtn() {
            console.log("table", this.table.tableData)
        },
        // 切换分页事件
        hadlePageSize(val) {
            this.table.page = val.page
            this.table.pageSize = val.pageSize
            this.schedule = 0
            this.getList()
        },
        // 重新校验
        reCheck() {
            if (this.table.tableData && this.table.tableData.length) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>是否重新校验当前页校验失败的数据？</p>",
                    onOk: () => {
                        this.vaildaUpdate(this.table.tableData)
                        return
                    },
                })
            } else {
                this.$Message.warning({
                    content: "暂无信息可校验",
                    background: true,
                })
                return
            }
        },
        changeCheck(val) {
            console.log("val", val)
        },
        // 点击初始化到社区架构按钮
        reSetData() {
            let arr = []
            this.table.tableData.map(item => {
                if (item.msgType == 1) {
                    arr.push(item)
                }
            })
            let list = JSON.parse(JSON.stringify(arr))
            let list2 = list.map(item => {
                return {
                    ...item,
                    houseName:item.address
                }
            })
            if (list2 && list2.length) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>是否确认同步当前页校验成功的数据？</p>",
                    onOk: () => {
                        this.updateList(list2)
                        return
                    },
                })
            } else {
                this.$Message.warning({
                    content: "当前页暂无可同步的数据",
                    background: true,
                })
                return
            }
        },
        // 分页接口
        async getList() {
            this.table.loading = true
            try {
                await this.$get("/syaa/pc/sy/user/gridManage/syUserPage", {
                    page: this.table.page,
                    pageSize: this.table.pageSize,
                    orgCode: this.$route.query.orgCode,
                    archivesId: "",
                }).then(res => {
                    this.table.loading = false
                    if (res && res.code == 200) {
                        this.table.tableData = res.dataList
                        this.table.total = res.maxCount
                        this.table.currentPage = res.currentPage
                        if (this.schedule != 1 && this.schedule != 2) this.schedule = 0
                    } else {
                        this.$Message.error({
                            content: "获取数据失败",
                            background: true,
                        })
                        return
                    }
                })
            } catch (err) {
                this.table.loading = false
                this.$Message.error({
                    content: "获取数据失败",
                    background: true,
                })
                return
            }
        },
        // 重新校验
        vaildaUpdate(list) {
            this.table.btnCheck = true
            try {
                this.$post("/syaa/pc/sy/user/gridManage/validateUser", list, { "Content-Type": "Application/json" }).then(res => {
                    this.table.btnCheck = false
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "校验成功",
                            background: true,
                        })
                        this.schedule = 2
                        this.table.tableData = res.dataList
                        this.table.total = res.dataList.length
                        return
                    } else {
                        this.$Message.error({
                            content: "校验失败,请重新进行校验",
                            background: true,
                        })
                        return
                    }
                })
            } catch (err) {
                this.table.btnCheck = false
                this.$Message.error({
                    content: "校验失败,请重新进行校验",
                    background: true,
                })
                return
            }
        },
        // 同步接口
        updateList(list) {
            this.table.btnLoading = true
            try {
                this.$post(
                    "/syaa/pc/sy/user/gridManage/syncUser",
                    {
                        orgCode: this.$route.query.orgCode,
                        operator: parent.vue.loginInfo.userinfo.realName,
                        syncUserPageVos: list,
                    },
                    { "Content-Type": "Application/json" }
                ).then(res => {
                    this.table.btnLoading = false
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "同步成功",
                            background: true,
                        })
                        this.schedule = 3
                        this.table.page = this.table.currentPage
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: "同步失败，请重试",
                            background: true,
                        })
                        return
                    }
                })
            } catch (err) {
                this.table.btnLoading = false
                this.$Message.error({
                    content: "同步失败，请重试",
                    background: true,
                })
            }
        },


        // 公共方法，防抖函数
        deboounceFun(e,value,id){
             if(e && e != ''){
                  this.$core.debounce(()=>{ 
                       this.table.tableData.map(item => {
                          if (item.id == id) {
                              item[value] = e
                          }
                      })
                  },'timer',1000);
              }else{
                  this.table.tableData.map(item => {
                       if (item.id == id) {
                           item[value] = ''
                       }
                   })
              }
        },

        // 拆分手机号
        splitPhone(){
            if(this.table.tableData.length == 0){
                this.$Message.warning({
                    content:'当前暂无数据',
                    background:true,
                })
                return
            }
            let ids = []
            this.table.tableData.map( item => {
                ids.push(item.id)
            })
            try {
                this.table.splitLoading = true
                this.$post("/syaa/pc/sy/user/gridManage/convertMobile", {
                    orgCode: this.$route.query.orgCode,
                    id:ids.join(',')
                }).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "拆分手机号成功",
                            background: true,
                        })
                        this.schedule = 1
                        this.getList()
                        this.table.splitLoading = false
                        
                    } else {
                        this.$Message.error({
                            content: "拆分手机号失败",
                            background: true,
                        })
                        this.table.splitLoading = false
                        return
                    }
                })
            } catch {
                this.table.clearLoading = false
                this.$Message.error({
                    content: "拆分手机号失败",
                    background: true,
                })
            }
        },
    },
}
</script>
